<template>
  <b-tooltip
    v-if="targetEl"
    :target="targetEl"
    placement="top"
    custom-class="tooltip-custom"
    noninteractive
  >
    <ul v-if="errors.length" class="pl-5 mb-0">
      <li v-for="(error, i) in errors" :key="i" class="text-nowrap text-left">
        {{ error }}
      </li>
    </ul>
  </b-tooltip>
</template>

<script>
export default {
  name: "ErrorsTooltip",
  components: {},
  props: {
    target: {
      type: null,
      required: true
    },
    errors: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {};
  },
  computed: {
    targetEl: function () {
      return this.target;
    }
  },
  mounted() {},
  methods: {}
};
</script>
